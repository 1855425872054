import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plank 3×1:00`}</p>
    <p>{`Back Extension 3×10`}</p>
    <p>{`Sumo Deadlift High Pulls 4×4\\@approx 75%\\`}</p>
    <p>{`then, 3 rounds for time:`}</p>
    <p>{`3-Rope Climbs to 15’`}</p>
    <p>{`20-GHD Situps`}</p>
    <p>{`6-Deadlifts (275/185) (Rx+ 315/225)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      